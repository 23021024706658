<template>
  <div class="mainPage">
    <p class="pageTitle">Sedgwick account</p>
    <div class="pageWrap">
      <div class="pass-word">
        <a-form-model
          class="pass-wrap"
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :colon="false"
        >
          <a-form-model-item label="account:" prop="username">
            <a-input v-model.trim="ruleForm.username" placeholder="Please Enter"/>
          </a-form-model-item>
          <a-form-model-item label="login password:" prop="newPassword">
            <a-input-password v-model="ruleForm.newPassword" type="password" show-password placeholder="Please enter"/>
            <div style="line-height: 1.5">
              The password length must be greater than or equal to 8 characters, including letters, numbers and special characters
            </div>
          </a-form-model-item>
          <a-form-model-item label=" " style="text-align: center" :colon="false">
            <a-button type="primary" @click="handleSubmit" :loading="loading">
              Confirm
            </a-button>
          </a-form-model-item>
          <a-form-model-item label=" ">
            <div style="line-height: 1.5">
              Batch settings through files, file format: .csv and file size shall not exceed 3M
              <a href="/template/set password.csv" download="set password.csv" style="margin-left: 20px;">template download</a>
            </div>
          </a-form-model-item>
          <a-form-model-item label=" ">
            <a-upload
              name="file"
              accept=".csv"
              :action="uploadUrl"
              :showUploadList="false"
              :headers="headers"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <a-button> <a-icon type="upload" /> Click to Upload </a-button>
            </a-upload>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hidePass: {
      type: Boolean,
      default: false
    },
    inputPass: {
      type: String,
      default: '123456'
    }
  },
  data () {
    var checkPassword = (rule, value, callback) => {
      const reg1 = /[0-9].*/
      const reg2 = /[a-zA-Z].*/
      const reg3 = /[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F].*/
      if (value === this.ruleForm.password) {
        callback(new Error('The new password cannot be the same as the old password.'))
      } else if (reg1.test(value) && reg2.test(value) && reg3.test(value)) {
        callback()
      } else {
        callback(new Error('The password length must be greater than or equal to 8 characters, including letters, numbers and special characters'))
      }
    }
    return {
      loading: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      passwordError: false,
      ruleForm: {
        username: '',
        newPassword: '',
      },
      rules: {
        username: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          { min: 8, message: 'The password length must be greater than or equal to 8 characters, including letters, numbers and special characters', trigger: 'blur' },
          {validator: checkPassword, trigger: 'blur'}
        ],
      },
      headers: { "token": localStorage.getItem('token') },
      uploadHeader: { "Content-Type": "multipart/form-data" },
      uploadUrl: PAGECONF.hostApi + '/platform/admin/ticketAdminAccount/v1/importSedgeWickInfo'
    }
  },
  methods: {
    beforeUpload (file) {
      if (file.size / 1024 / 1024 > 3) { 
        this.$message.error(`File must smaller than 3MB!`)
        return
      }
    },
    handleChange (info) { 
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} file import successfully`)
        } else {
          this.$error({
            title: 'Error',
            content: info.file.response.errorMsg
          })
        }
      } else if (info.file.status === 'error') {
        this.$message.error('network error')
      }
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        try {
          this.loading = true

          const res = await this.$http.post('/platform/admin/ticketAdminAccount/v1/changeSedgeWickPassword', {...this.ruleForm })
          if (res.code === 0) {
            this.$message.success('Login password has been set');
            this.$refs.ruleForm.resetFields();
          }
        } catch(error) { 
          if (error.code === 430008 || error.code === 430021) { 
            this.$message.error('Please check, password setting failed.');
          }
        } finally { 
          this.loading = false
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.pass-wrap{
  width: 800px;
  margin: 0px auto;
}
</style>